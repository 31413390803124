import React from "react";
import { graphql } from "gatsby";

import Page from "~/components/Page";

const RefundAndShippingPolicy = ({ data, location }) => {
  return <Page data={data} location={location} />;
};

export const query = graphql`
  {
    contentfulPage(slug: { eq: "refund-and-shipping-policy" }) {
      title
      content {
        raw
      }
    }
  }
`;

export default RefundAndShippingPolicy;
